import { uniq } from "lodash";

export class ObjectService {
  static isEqual(obj1: any, obj2: any): boolean {
    if (!obj1 && !obj2) return true;
    if (!obj1 || !obj2) return false;

    const keys = uniq([...Object.keys(obj1), ...Object.keys(obj2)]);

    return keys.every(key => (
      key in obj1
        && key in obj2
        && (
          obj1[key] == obj2[key]
          || (
            typeof obj1[key] == "object"
              && typeof obj2[key] == "object"
              && this.isEqual(obj1[key], obj2[key])
          )
        )
    ));
  }
}
