
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import { HostInvitation } from "@/model/host-invitation.model";
import EmailTextField from "@/components/custom-components/EmailTextField.vue";
import { getHostInvitationModuleNamespace } from "@/store/store.utils";
import { HostRole, Nullable } from "@/model/types";
import { dismissAction, logAndExtractMessage } from "@/utils/utils";
import { USER_HOST_ROLES } from "@/utils/user-utils";
import { Host } from "@/model/host.model";
import { ObjectService } from "@/services/object.service";

export type RawInvitation = Omit<Nullable<HostInvitation>, "host"> & {
  host: Host;
} | null

@Component({
  components: { EmailTextField },
})
export default class HostInvitationDialog extends Vue {
  open = false;

  loading = false;

  hostRoles = USER_HOST_ROLES;

  invitation: Nullable<HostInvitation> | null = null;

  email = "";

  isValidEmail = false;

  roles: HostRole[] = [];

  host: Host | null = null;

  @Action("addOrSaveHostInvitation", getHostInvitationModuleNamespace())
  addOrSaveHostInvitation!: (data: RawInvitation) => Promise<void>

  get allowSend() {
    return this.email
      && this.isValidEmail
      && this.host
      && this.roles.length
      && !ObjectService.isEqual({
        id: this.invitation?.id,
        email: this.invitation?.email,
        host: this.invitation?.host,
        roles: this.invitation?.roles,
      }, {
        id: this.invitation?.id,
        email: this.email,
        host: this.host,
        roles: this.roles,
      });
  }

  openDialog(invitation: RawInvitation) {
    if (!invitation?.host) throw Error("You can't invite user without host!");

    this.invitation = invitation || null;
    this.email = invitation?.email || "";
    this.isValidEmail = !!invitation?.email;
    this.roles = invitation?.roles || [];
    this.host = invitation.host;
    this.open = true;
  }

  async save() {
    if (!this.roles || !this.email || !this.host) {
      throw Error("You need to provide host, roles and email");
    }

    try {
      this.loading = true;

      await this.addOrSaveHostInvitation({
        id: this.invitation?.id,
        email: this.email,
        host: this.host,
        roles: this.roles,
      });
      this.open = false;
    } catch (e) {
      Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
    }
    this.loading = false;
  }
}
