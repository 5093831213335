
import {
  Component, Emit, Prop, Vue, Watch,
} from "vue-property-decorator";
import { EMAIL_REGEX } from "@/utils/utils";

@Component({})
export default class EmailTextField extends Vue {
  @Prop()
  value!: string;

  @Prop({ default: "" })
  label!: string;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: false })
  loading!: boolean;

  get emailValidation() {
    return (this.value && EMAIL_REGEX.test(this.value)) || "Must be a valid email address";
  }

  @Emit("input")
  emitInput(value: string) {
    return value;
  }

  @Watch("emailValidation")
  onUpdValidation() {
    this.emitValidation();
  }

  @Emit("valid")
  emitValidation() {
    return this.emailValidation === true;
  }
}
